$ns: bulletin-board;
$ns-item: bulletin-board-item;

.#{$ns-item} {
  a {
    display: table;
    border-bottom: 2px solid $color-background;
    margin-bottom: 20px;
    padding-bottom: 30px;
    padding-top: 5px;
    color:$color-text;
    text-decoration: none;
    @include transition(color $hover-transition-duration);

    &:hover{
      .content
      {
        .title{
          color:$color-brand;
        }
      }
    }

    .icon {
      display: table-cell;
      vertical-align: top;
      width: 105px;
      text-align: center;
      @include respond-below(sm){
        display:none;
      }
    }
    .content {
      display: table-cell;
      vertical-align: top;
      .title {
        font-size: 18px;
        font-weight: bold;
        display: block;
        margin-bottom: 10px;
        @include respond-below(sm){
          color:$color-brand;
          font-size:16px;
        }
      }
      .desc {
        font-size: 14px;
        line-height: 20px;
        display: block;
      }
    }
    .options {
      display: table-cell;
      vertical-align: top;
      width: 240px;
      padding-top:26px;
      padding-left:20px;
      @include respond-below(sm){
        display:none;
      }
    }
  }
}

