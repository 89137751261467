$ns: icon-tile;

.#{$ns} {

  text-align: center;

  a {
    padding-top:15px;
    padding-bottom:10px;
    display:block;
    color:$color-text-light;
    text-decoration: none;
    cursor: pointer;

    .icon{
      height:60px;
      width:60px;
    }
    span{
      padding-top:8px;
      display:block;
      font-size:16px;
    }


    @at-root .svg-hover#{&}{

      svg path {
        @include transition(fill $hover-transition-duration linear);
      }

      span{
        @include transition(color $hover-transition-duration linear);
      }

      &:hover, &.active {
        svg path{
          fill: $color-tile-hover !important;
        }
        span{
          color:$color-tile-hover;
        }
      }
    }

    @at-root .background-hover#{&}{
      @include transition(background-color $hover-transition-duration linear);

      &:hover, &.active{
        background-color: $color-brand;
      }
    }
  }
}