/*

Variable Dependencies: $color-brand, $header-height

*/
@import "../../sass/variables";
@import "../../sass/helpers";
@import "../../sass/typography";

$ns: header;
$menu-width:320px;
$menu-left-width:215px;

.#{$ns} {
  position: fixed;
  width:100%;
  top:0;
  z-index:100;

  .#{$ns}-nav{
    height: $header-height;
    width: 100%;
    background-color: $color-brand;
    position: fixed;
    z-index:12;
    //this is the ensure everything can be centered nicely in the nav
    .container,.row,.col-xs-12{
      height:100%;
    }

    .#{$ns}-menu-button {
      display:inline-block;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index:1;

      .bar {
        width: 35px;
        height: 5px;
        display:block;
        background-color: $color-text-light;
        margin: 2px 0;
        border-radius: 5px;
        @include transition(background-color $hover-transition-duration);
      }

      &:hover{
        .bar{
          background-color:$color-brand-alt;
        }
      }
    }

    .#{$ns}-brand-large-logo{
      position:absolute;
      left:80px;
      height:36px;
      top:50%;
      @include transform(translateY(-50%));

      @include respond-below(md)
      {
        display:none;
      }
    }

    .#{$ns}-brand-small-logo{
      position:absolute;
      left:65px;
      height:36px;
      top:50%;
      @include transform(translateY(-50%));

      display:none;

      @include respond-below(md)
      {
        display:block;
      }
    }

    .#{$ns}-menu-right{
      position: relative;
      display: table;
      top: 15px;
      width: 100%;
      padding-left:50px;

      .#{$ns}-menu-search{
        display:table-cell;
        width: 100%;
        position: relative;
        height:41px;


        .#{$ns}-menu-search-box{

          right:0;
          top:0;
          position: absolute;
          width: 220px;
          overflow: hidden;

          @include respond-below(md)
          {
            width:100%;
          }


          .search-error-message{

            height:41px;
            background-color: white;
            width:100%;
            border: 1px solid red;
            color:red;
            padding:12px;
            display:none;
          }
          .#{$ns}-menu-search-box-overflow{
            overflow: hidden;
          }
          .#{$ns}-menu-search-box-inner {
            position: relative;

            @include respond-below(md){
              left:100%;

              @include transition(left $menu-display-duration);

              &.open{
                left:0;
              }
            }

            input{
              width:100%;

              padding-left: 30px;
              padding-right: 15px;
              height:41px;
              border:none;
              border-radius: 3px;
              position: relative;
              float:right;

              @include respond-below(md)
              {
                padding-left: 15px;
              }
            }

            .#{$ns}-menu-search-icon{
              text-decoration: none;
              cursor: pointer;
              
              .fa-search{
                color: $color-brand;
                position:absolute;
                left:10px;
                top:12px;

                @include respond-below(md)
                {
                  display:none;
                }
              }
            }
          }
        }


      }
      .#{$ns}-menu-buttons{

        display: table;
        height:41px;
        white-space: nowrap;
        text-align: right;
        padding-left:5px;
        position: relative;

        .#{$ns}-menu-button-wrapper{
          display:table-cell; vertical-align: middle;
        }

        .#{$ns}-menu-buttons-collapsible {

          padding-top:8px;
          padding-left:4px;

          @include transition(width linear $menu-display-duration);
          overflow: hidden;
          display: inline-block;
          height:41px;
          position: relative;

          @include respond-below(md)
          {
            width:0;
            &.open{
              width: 138px;

              @include respond-below(sm){
                width:108px;
              }
            }
          }

        }
        .#{$ns}-menu-search-button
        {
          @include respond-below(md)
          {
            display:inline-block;
          }
          color:$color-text-light;
          display: none;
          position: relative;
          cursor: pointer;
          &:hover{
            .fa-circle {
              color: $color-brand-alt;
            }
          }

          .fa-circle{
            @include transition(color linear $hover-transition-duration);
            font-size:32px;
          }

          .fa-search
          {
            color:$color-brand;
            font-size:16px;
            position: absolute;
            top: 7px;
            left: 7px;
          }
        }

        .#{$ns}-menu-icon-button
        {
          color:$color-text-light;
          display: inline-block;
          margin-left:15px;
          font-size:24px;
          min-width:28px;
          height:41px;
          text-align: center;
          position: relative;
          cursor: pointer;

          @include respond-below(sm){
            margin-left:5px;
          }


          @include transition(color linear $hover-transition-duration);
          .badge{
            background-color:$color-status-1;
            color: $color-brand;
            position: absolute;
            right:-8px;
            top:-8px;
          }

          &:hover{
            color:$color-brand-alt;
          }
        }

        .#{$ns}-menu-expand-button
        {
          @include respond-below(md){
            display: inline-block;
          }

          color:$color-text-light;
          display: none;
          margin-left:8px;
          font-size:24px;
          @include transition(color linear $hover-transition-duration);
          min-width:24px;
          text-align: center;
          &:hover{
            color:$color-brand-alt;
          }

          @include respond-below(sm){
            margin-left: 0;
          }
        }
      }
    }


  }
  .#{$ns}-menu {
    position:fixed;
    z-index:11;
    width:$menu-width;
    transform: translateY(-100vh);

    @include if-ie-10-plus(){
      transform: translateY(-100%);
    }

    @include respond-below(sm)
    {
      left:-$menu-width;
      @include transition(left $menu-display-duration, transform 0s $menu-display-duration); //animate tops to prevent flicker on resize from phone to tablet/desktop
    }

    @include respond-above(sm)
    {
      @include transition(transform $menu-display-duration);
    }


    @at-root .open#{&}{

      @include respond-below(sm)
      {
        transform: translateY($header-height);
        left:0;
        @include transition(left $menu-display-duration, transform 0s 0s); //animate tops to prevent flicker on resize from phone to tablet/desktop
      }

      @include respond-above(sm)
      {
        transform: translateY($header-height);
      }
    }

    .#{$ns}-menu-top{
      background-color:$color-brand;
      height:50px;
      box-shadow: inset 0 30px 40px -30px rgba(0,0,0,0.4);

      .menu-close{
        color:$color-text-light;
        @include nav-menu-top;
        position: absolute;
        left: 15px;
        top: 20px;
        text-decoration: none;
        cursor: pointer;
      }

      .menu-back{
        color:$color-text-light;
        @include nav-menu-top;
        position: absolute;
        right: 15px;
        top: 20px;
        text-decoration: none;
        cursor: pointer;
      }
    }
    .#{$ns}-menu-content
    {
      display: table;

      .#{$ns}-menu-content-left{
        width:105px;
        background-color: $color-menu-left;
        display: table-cell;
        vertical-align: top;

        .#{$ns}-menu-content-left-contents {
          max-height: calc(100vh - 120px);
          overflow: auto;
        }
      }


      .#{$ns}-menu-content-right{
        width:$menu-left-width;
        display: table-cell;
        vertical-align: top;
        background-color: $color-menu-right;
        .#{$ns}-menu-content-right-contents {
          max-height: calc(100vh - 120px);
           overflow-y: hidden;
           overflow-x: visible;
        }
      }
    }

    .#{$ns}-menu-nav-primary
    {
      .icon-tile{
        @include nav-menu-left-font;
      }
    }

    .#{$ns}-menu-nav-children
    {
      width:$menu-left-width;
      overflow: hidden;
      .#{$ns}-menu-nav-item-container{
        @include transition(margin-left,$hover-transition-duration);

        position: relative;

        .menu-nav-level{
          width:$menu-left-width;
          float:left;
          overflow: auto;
          height:100%;
          max-height: calc(100vh - 120px);


          padding:15px;

          .menu-nav-level-title{
            @include nav-menu-level-title-font;
            color: $color-brand;
            margin-bottom: 5px;
          }
          .menu-nav-level-items{
            .menu-nav-level-item{
              a {
                border-top: 1px solid $color-menu-left;
                min-height: 52px;
                display: table;
                padding:10px 0;
                width: 100%;
                cursor: pointer;
                position: relative;

                &:hover, &:focus {
                  text-decoration: none;

                  .nav-item-wrapper i{
                    color:$color-brand-alt;
                  }
                }

                .nav-item-wrapper {
                  display: table-cell;
                  vertical-align: middle;
                  padding: 0 30px 0 15px;

                  .menu-nav-level-item-name {
                    display: block;
                    color: $color-menu-text;
                    @include nav-menu-level-item-name
                  }

                  .menu-nav-level-item-desc {
                    margin-top: 2px;
                    display: block;
                    color: $color-menu-text;
                    @include nav-menu-level-item-desc;
                  }

                  i {
                    position: absolute;
                    top: 18px;
                    right: 0;
                    color:$color-brand;
                    @include transition(color $hover-transition-duration linear);
                  }
                }
              }
            }
          }
        }
      }

    }
  }
  .#{$ns}-menu-overlay{
    height:100vh;
    position:fixed;
    z-index:10;
    background-color: $color-menu-overlay;
    top:0;
    left:0;
    width:100%;
    visibility:hidden;
    opacity: 0;
    @include transition(visibility $menu-display-duration, opacity $menu-display-duration linear);

    @at-root .open#{&}{
      visibility:visible;
      opacity: 1;
    }
  }
}

