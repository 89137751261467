@import "../../sass/variables";
@import "../../sass/helpers";

$ns: panel-section;

.#{$ns}{

  padding-top: 35px;
  padding-bottom: 35px;
  font-style: normal;
  font-size: 16px;
  color: $color-text;
  margin-bottom:21px;

  @include respond-below(sm){
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &.#{$ns}-white{
    background-color: $color-panel;
  }

  &.add-panel{

    .#{$ns}-title {
      position: relative;
      margin-bottom: 0;

      @include respond-below(sm)
      {
        padding-right:55px;
      }
    }
    .add-button{
      position:absolute;
      top:-8px;
      right:39px;
      @include respond-below(sm)
      {
        top:-5px;
        right:15px;
      }
    }
  }

  .#{$ns}-title{
    padding:0 39px;
    font-size: 26px;
    color: $color-brand;
    margin-bottom: 40px;

    @include respond-below(sm){
      font-size: 18px;
      margin-bottom: 12px;
      padding:0 15px;
    }

    &.has-sub{
      margin-bottom: 5px;
    }
  }

  .collapsible-panel{
    .#{$ns}-title{
      margin-bottom: 0px;
    }
    .#{$ns}-inner{
      padding-top: 40px;
    }
  }

  .#{$ns}-sub-title{
    padding:0 39px;

    margin-bottom: 40px;
    color:$color-input-placeholder;
    @include respond-below(sm){
      margin-bottom: 12px;
      padding:0 15px;

    }
  }

  .#{$ns}-inner{
    position: relative;
    width:100%;
    max-width: 869px;

    padding-left:39px;
    padding-right:39px;

    @include respond-below(sm){
      padding-left: 15px;
      &.normal-pad{
        padding-right: 15px;
      }
    }

    .mandatory-field{
      color: $color-mandatory-field-text;
    }

    .#{$ns}-button-wrapper{
      min-height:41px;
      text-align: right;
      margin-top:10px;

      .#{$ns}-button{
        padding: 8px;
        width: 132px;
        height: 41px;
        font-size: 16px;

        @include respond-below(sm){
          height: 30px;
          width: 86px;
          font-size: 14px;
        }

        .#{$ns}-button-icon{
          position: relative;
          display: block;

          &::before{
            position: absolute;
            top: 50%;
            @include transform(translateX(-50%) translateY(-50%));
            font-family: "FontAwesome";
            font-weight: 100;
            content: $add-icon;
            color: $color-text-light;
          }
        }
      }

      &.no-top-margin{
        margin-top:0px;
      }

      &.market-accessories-mod{
        position: absolute;
        left: 50%;
        top: 50%;
        @include transform(translateY(-50%));
      }
    }
  }
}

input[type="number"]{
  text-align: right;
}