@import "../../sass/variables";
@import "../../sass/helpers";

.checkbox{
  padding-left: 40px;
  padding-top:0px !important;
  height:40px;
  position: relative;

  @include respond-below(sm){
    padding-left: 26px;
  }

  label{
    height:100%;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 20px;

    span {
      top:50%;
      position: relative;
      display: inline-block;
      @include transform(translateY(-50%));
    }

    &::before{
      width: 40px;
      height: 40px;
      margin-left: -40px;
      border: 1px solid $color-input-border;
      border-radius: 2px;

      top:50%;
      @include transform(translateY(-50%));

      @include respond-below(sm){
        height:26px;
        width: 26px;
        margin-left: -26px;
      }
    }

    &::after{
      display: inline-block;
      position: absolute;
      width: 40px;
      height: 40px;
      margin-left: -40px;
      padding-left: 0px;
      color: $color-text;

      top:50%;
      @include transform(translateY(-50%));

      @include respond-below(sm){
        height:26px;
        width: 26px;
        margin-left: -26px;
      }
    }
  }

  //Disabled Styles
  input[type="checkbox"]:disabled,
  input[type="radio"]:disabled{
    cursor: default;
    + label{
      opacity: 1;
      cursor: default;
      &::before{
        background-color: $color-field-disabled;
        border:transparent;
        cursor: default;
      }
    }
  }
}

input[type="checkbox"]{
  opacity: 0;
  z-index: 1;
  cursor: pointer;

  &:focus + label::before{
    outline-color: $color-input-focus-outline !important;
  }

  &:checked + label::after {
    font-family: $font-family-icon;
    content: $check-icon;
    color: $color-brand;
    font-size:16px;
    padding:12px;

    @include respond-below(sm){
      padding:5px;
    }
  }
}