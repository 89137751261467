$ns-box: marketing-box;
$ns-tile: marketing-tile;

.#{$ns-box}{
  background-color: $color-brand;
 // height:300px;
  width:100%;
  margin-bottom: 20px;

  @include respond-above(sm) {
    .row {
      display: table;
      [class*="col-"] {
        display: table-cell;
        height:100%;
        float:none;
        vertical-align: top;
      }
    }
  }
}

.#{$ns-tile}{
  padding:20px;
  height:100%;

  .inner{

    background-color: $color-panel;
    width: 100%;
    padding:30px;
    position: relative;
    min-height:240px;
    height:100%;

    @include respond-below(md){
      min-height:280px;
    }
    @include respond-below(sm){
      min-height:175px;
    }

    &.image{
      background-position: 50% 50%;
      background-size: cover;
    }

    .title{
      font-size:30px;
      font-weight:bold;
      color:$color-text;
      margin-bottom:15px;
      @include respond-below(sm){
        font-size:22px;
      }
    }

    .blurb{
      font-size:20px;
      line-height:22px;
      color:$color-text;
      @include respond-below(md){
        font-size:16px;
      }
    }
  }
}