$ns: greeting;

.#{$ns} {
  font-size:52px;
  font-weight: 300;

  padding:40px 60px;

  @include respond-at-below(sm)
  {
    font-size:36px;
    padding:40px 0px;
  }
}