@import "../../sass/variables";
@import "../../sass/helpers";

$ns: notes-modal;
$padding-sides:25px;
$padding-sides-mobile:10px;

.#{$ns}
{
  .modal-header{
    border:none;
    padding-top:5px;
    padding-right:5px;
    padding-left:$padding-sides;
    padding-bottom: 10px;

    @include respond-below(sm) {
      padding-left:$padding-sides-mobile;
    }

    .close{
      font-size:34px;
    }

    .details{
      float:left;
      font-weight: bold;
      font-size:20px;
      padding-top:10px;
      @include respond-below(sm) {
        padding-top:14px;
        font-size:16px;
      }
    }
  }

  .modal-footer{
    border:none;
    padding-left:$padding-sides;
    padding-right:$padding-sides;

    @include respond-below(sm) {
      padding-left:$padding-sides-mobile;
      padding-right:$padding-sides-mobile;
    }

    .afs-button{
      @include respond-below(sm){
        min-width: 68px;
        margin-right: 3px;
      }
    }
  }

  .modal-content{
    background-color: $color-background;
  }

  .modal-body{
    padding-top:0;
    padding-left:$padding-sides;
    padding-right:$padding-sides;

    @include respond-below(sm) {
      padding-left:$padding-sides-mobile;
      padding-right:$padding-sides-mobile;
    }
    padding-bottom:0;

    .modal-body-inner{
      background-color: $color-panel;
      padding: 15px 15px 10px;

      .modal-body-inner-title{
        color: $color-brand;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
      }

      .file-input {
        position: relative;
        margin-right: -15px;
        margin-left: -15px;
        padding-left: 15px;
        padding-right: 15px;

        .file {
          position: relative;
          text-align: right;
          @include opacity(0);
          z-index: 2;
        }

        .fake-file {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          z-index: 1;
          padding-right: 15px;
          padding-left: 15px;
        }
      }
    }
  }

  .#{$ns}-text{
    width:100%;
    height:300px;
    border: 2px solid $color-input-border;
    border-radius: 2px;
    resize:none;
    padding:15px;

      @include respond-below(sm) {
          height:200px;
      }
    }
}