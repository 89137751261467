@import "../../sass/variables";
@import "../../sass/helpers";

$ns: point-badge;

.#{$ns}{
  display: inline-block;
  border-radius: 10px;
  color: $color-text-light;
  width: 145px;
  height: 145px;
  position: relative;
  text-align: center;
  padding: 18px;
  margin-bottom:12px;
  background-color: $color-point-badge;


  .#{$ns}-content{
    font-size: 14px;
    position: absolute;
    top:50%;
    margin-top:-10px;
    width:100%;
    left:0;
    @include transform(translateY(-50%));

    >div:not(:last-child){
      padding-bottom: 5px;
    }

    .#{$ns}-content-focus{
      font-size: 40px;

      &.text-mid{
        font-size:29px;
      }
      &.text-small{
        font-size:22px;
      }
    }
  }

  .#{$ns}-descriptor{
    color: $color-point-badge-descriptor;
    font-size: 14px;
    width: 100%;
    position: absolute;
    bottom: 18px;
    left: 0;
  }

  @include respond-below(sm){
    width:120px;
    height:120px;

    .#{$ns}-content{
      font-size: 12px;

      .#{$ns}-content-focus{
        font-size: 32px;

        &.text-mid{
          font-size:24px;
        }
        &.text-small{
          font-size:18px;
        }
      }
    }
    .#{$ns}-descriptor{
      font-size:11px;
    }
  }
}


.#{$ns}-container {

  ul{
    display:block;
    margin:0 auto;
    font-size:0;
  }
  li{
    display:inline-block;
    width:20%;
    text-align: center;
    @include respond-below(lg){
      width:25%;
    }
    @include respond-below(md){
      width:25%;
    }
    @include respond-below(sm){
      width:50%;
      margin:0;

    }
  }
}