@import "../../sass/variables";
@import "../../sass/helpers";

$ns: afs-button;

.#{$ns} {

  border: 0 none;
  border-radius: 2px;

  text-align: center;
  font-style: normal;
  font-size: 20px;
  color: $color-text-light;
  @include transition(background-color $hover-transition-duration);

  cursor: pointer;
  text-decoration: none;

  display: inline-block;
  padding: 8px;
  margin-right: 20px;
  min-width: 132px;
  height: 41px;

  &.circle{
    width:38px;
    height:38px;
    min-width: 38px;
    border-radius:50%;
    font-size: 22px;

    @include respond-below(sm){
      width:28px;
      min-width: 28px;
      height:28px;
      font-size: 18px;
      line-height: 14px;
    }
  }

  &:last-child {
    margin-right: 0px;
  }

  @include respond-below(sm) {
    height: 30px;
    min-width: 86px;
    font-size: 14px;
    margin-right: 5px;
  }

  &.button-delete{
    background-color: $color-button-0;

    &:hover {
      background-color: $color-button-2-hover;
    }

    &:active {
      background-color: $color-button-2-active;
    }
  }

  &.button-colour-0 {
    background-color: $color-button-0;

    &:hover {
      background-color: $color-button-0-hover;
    }

    &:active {
      background-color: $color-button-0-active;
    }
  }
  &.button-colour-1 {
    background-color: $color-button-1;

    &:hover {
      background-color: $color-button-1-hover;
    }

    &:active {
      background-color: $color-button-1-active;
    }
  }
  &.button-colour-2 {
    background-color: $color-button-2;

    &:hover {
      background-color: $color-button-2-hover;
    }

    &:active {
      background-color: $color-button-2-active;
    }
  }
  &.button-colour-3 {
    background-color: $color-button-3;

    &:hover {
      background-color: $color-button-3-hover;
    }

    &:active {
      background-color: $color-button-3-active;
    }
  }
  &.button-colour-4 {
    background-color: $color-button-4;

    &:hover {
      background-color: $color-button-4-hover;
    }

    &:active {
      background-color: $color-button-4-active;
    }
  }

  &.button-colour-5 {
    background-color: $color-button-0;

    &:hover {
      background-color: $color-button-3;
    }

    &:active {
      background-color: $color-button-3;
    }
  }
}

.button-footer{
  text-align: right;
  padding-bottom:27px;
  @include respond-below(sm){
    text-align: center;
  }
}