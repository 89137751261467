$ns: table;

.#{$ns}{
  &.variant{
    &>thead{
      background-color: $color-table-head-alt;
      color:$color-text-light;
    }
  }
  &>thead>tr>th{
    font-size: 18px;
    font-weight: bold;
  }

  &>tbody>tr>td{
    border:none;
    font-size:14px;
  }

  &.table-striped>tbody>tr:nth-of-type(odd){
    background-color: $color-table-row-alt;
  }

  margin-bottom:0;
}

