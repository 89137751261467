@import "../../sass/variables";
@import "../../sass/helpers";

$ns: top-panel-section;

.#{$ns}-container{
  min-height:148px;

  @include respond-in(sm){
    min-height:202px;
  }
  @include respond-below(sm){
    min-height:126px;
  }
}

.#{$ns} {
  min-height: 87px;
  margin-top: 20px;
  margin-bottom: 35px;
  padding:24px;

  font-size: 26px;

  @include respond-below(sm) {
    margin-top: 0px;
    margin-bottom: 20px;
    padding: 10px;
  }
  .title-section {
    padding-left:0;
    display:table;
  }

  .deal-section {
    padding-right:0;
    display:table;
  }

  .deal-name-section{
    position: relative;
    font-size:16px;
    color:$color-text;

    margin-top: 10px;
    .#{$ns}-deal-display-name{
      text-overflow: ellipsis;
      overflow:hidden;
      white-space: nowrap;
    }
  }

  &.#{$ns}-white{
    background-color: $color-panel;
  }

  &.sticky {
    @include transform(translate3d(0,0,0));
    z-index: 10;
    position: fixed;
    left:0;
    width:100%;
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 66px;

    .#{$ns}-fixed-container{
      @include respond-above(sm){
        padding-left:39px;
        padding-right:39px;
      }
    }

    .infobox-icon {
      &.open{
        .infobox-text{
          top: -9px;
        }
      }
    }
  }

  .#{$ns}-deal{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: $color-text;
    position: relative;
    display: table-cell;
    vertical-align: middle;
    height: 41px;

    .deal-info{
      position:relative;
    }

    .deal-status{
      margin-top:10px;

      @include respond-below(sm){
        margin-top: 5px;
      }
    }

    .deal-status-progress{
      color: $color-deal-status-progress;
    }

    .infobox-icon{
      display: inline-block;
      position: absolute;
      right: 0;
      margin-left:5px;

      .infobox-icon-symbol{
        text-align: center;
        padding-left: 0px;
        &::before{
          font-family:$font-family-icon;
          font-weight: 100;
          font-size: 10px;
          content: $right-chevron-icon;
          width: 100%;
          min-width: 17px;
          display: block;
          line-height: 16px;
        }

        color: $color-brand;
        background-color: $color-panel;
        border: 1px solid $color-infobox-top-panel-border;
      }

      &.open{
        .infobox-icon-symbol{
          color:  $color-panel;
          background-color: $color-brand;

          @include respond-below(sm){
            &::before{
              content: $down-chevron-icon;
            }
          }
        }
      }

      .infobox-text{
        background-color: $color-panel;
        color: $color-text;
        border: 1px solid $color-infobox-top-panel-border;

        padding: 14px 20px;
        min-height: 68px;
        height: auto;
        width:280px;
        left: 10px;
        top: -22px;
        @include transform(translateY(0));

        @include respond-below(sm){
          left:0;
          transform: translateX(-33%);
        }

        >span{
          display:block;
          line-height: 20px;
        }
      }
    }
  }

  .#{$ns}-title{
    font-style: normal;
    font-size: 22px;
    color: $color-brand;
    display: table-cell;
    height: 41px;
    width: 100%;

    @include respond-below(md) {
      text-align: right;
    }
    @include respond-below(sm){
      font-size: 20px;
    }

    .#{$ns}-major-category{
      margin-bottom:5px;
    }
    .#{$ns}-minor-category{
      font-size:16px;
      color: $color-text;
    }
  }

  .#{$ns}-button-group{
    float:right;
    font-size:0;
    text-align: center;

    @include respond-below(md){
      margin-top: 15px;
      float:none;
    }

    .afs-button{
      @include respond-in(md){
        margin-right: 15px;

        &:last-child{
          margin-right: 0;
        }
      }
    }
  }

}

.notes-icon {
  &:before {
    font-family: $font-family-icon;
    font-size: 15px;
    content: $notes-icon;
    padding-right:10px;

    @include  respond-below(md){
      padding-right: 5px;
      font-size: 13px;
    }
  }
}