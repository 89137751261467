$ns-form-control: form-control;
$ns-control-label: control-label;
$ns-drop-down: fs-dropdown;

//$max-field-width: 299px; Not used as isnt reliable

.#{$ns-form-control}-with-icon{
  .#{$ns-form-control}
  {
    padding-right:45px;
  }

  .icon{
    position: absolute;
    top:10px;
    right:30px;
    font-size:20px;
    color: $color-brand;
  }
}

.error-message {
  display: none;
}

.#{$ns-form-control} {
  border: solid 1px $color-input-border;
  border-radius: 2px;
  padding: 10px 15px;
  height:41px;
  -webkit-appearance: none;
  box-shadow: none !important;

  &:focus{
    border-color: $color-brand;
    @include box-shadow(none);
  }

  //Disabled Styles
  &[disabled]{
    border:transparent;
    cursor: default;
    background-color: $color-field-disabled;
    color: $color-text;

    ~i{
      display: none;
    }
  }
}

.#{$ns-control-label}{
  padding-top:0 !important;
  text-align: left !important;

  @include respond-above(sm) {
    height:41px;
    position: relative;
    label {
      top: 50%;
      @include transform(translateY(-50%));
      position: absolute;
    }
  }

  @include respond-below(sm)
  {
    padding-left:30px;
    padding-bottom:5px;
  }
}

.field-space-sm{
  @include respond-below(sm){
    margin-top:12px;
  }
}

.has-error{
  .#{$ns-drop-down} {
    .#{$ns-drop-down}-selected
    {
      border-color: $color-panel-error;
    }
  }

  .#{$ns-control-label} {
    color:$color-panel-error
  }

  .#{$ns-form-control} {
    border-color:$color-panel-error
  }

  .checkbox label, .checkbox label::before{
    border-color:$color-panel-error;
    color:$color-panel-error;
  }

  .error-message{
    font-size: 12px;
    color: $color-panel-error;
    padding-top: 8px;
    display: inline-block;
  }
}

.has-success{
  .#{$ns-drop-down} {
    .#{$ns-drop-down}-selected
    {
      border-color: $color-panel-success;
    }
  }

  .#{$ns-control-label} {
    color:$color-panel-success;
  }

  .#{$ns-form-control} {
    border-color:$color-panel-success;
  }

  .checkbox label, .checkbox label::before{
    border-color:$color-panel-success;
    color:$color-panel-success;
  }
}

.has-warning{
  .#{$ns-drop-down} {
    .#{$ns-drop-down}-selected
    {
      border-color: $color-panel-warning;
    }
  }

  .#{$ns-control-label} {
    color:$color-panel-warning;
  }

  .#{$ns-form-control} {
    border-color:$color-panel-warning;
  }

  .checkbox label, .checkbox label::before{
    border-color:$color-panel-warning;
    color:$color-panel-warning;
  }
}

.#{$ns-drop-down}
{
  .#{$ns-drop-down}-selected{
    height: 41px;
    border: solid 1px #d2d2d2;
    border-radius: 2px;
    padding: 10px 15px 8px 15px;

    @include transition(border-color ease-in-out .15s)
  }

  &.#{$ns-drop-down}-focus{
    .#{$ns-drop-down}-selected {
      border-color: $color-brand;
    }
  }
}


.form-group{
  margin-bottom:12px;

  &.section-expanded{
    background-color: $color-background;
    padding-top:12px;
    @include respond-below(sm){
      margin-right:-39px;
      padding-right:24px;
    }
  }

  .field-expand-section{
    clear:both;
    height:0;
    background-color: $color-background;
    overflow: hidden;
      &.active{
        padding-top:12px;

        height: auto;
        overflow: visible;
      }

  }
}