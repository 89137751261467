$ns: footer;
$footer-height:35px;

.#{$ns} {
 height:$footer-height;
  background-color: $color-brand;
 position: relative;

 .back-to-top{
  color:$color-text-light;
  cursor: pointer;
  float:right;
  height:35px;
  width:35px;
  text-align: center;
  i{
   top:7px;
   position: relative;

  }
 }
}