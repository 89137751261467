@include font('Lato','Lato-Black',900,normal);
@include font('Lato','Lato-Bold',700,normal);
@include font('Lato','Lato-BoldItalic',700,italic);
@include font('Lato','Lato-Hairline',100,normal);
@include font('Lato','Lato-HairlineItalic',100,italic);
@include font('Lato','Lato-Italic',400,italic);
@include font('Lato','Lato-Light',300,normal);
@include font('Lato','Lato-LightItalic',300,italic);
@include font('Lato','Lato-Regular',400,normal);


/*
100
Thin (Hairline)
200
Extra Light (Ultra Light)
300
Light
400
Normal
500
Medium
600
Semi Bold (Demi Bold)
700
Bold
800
Extra Bold (Ultra Bold)
900
Black (Heavy)
*/