//breakpoint variables
$break-extra-extra-small: 320px;
$break-small: 768px;
$break-medium: 992px;
$break-large: 1200px;

$header-height: 70px;

$hover-transition-duration: 0.2s;
$menu-display-duration: 0.2s;

$font-family-icon: 'FontAwesome' !default;
$dropdown-icon: "\f078" !default;
$check-icon:  "\f00c" !default;
$right-chevron-icon:  "\f054" !default;
$left-chevron-icon:  "\f053" !default;
$down-chevron-icon:  "\f078" !default;
$up-chevron-icon:  "\f077" !default;
$notes-icon: "\f040" !default;
$calender-icon: "\f073" !default;
$add-icon: "\f067" !default;

$color-brand: #0079c8;
$color-brand-alt: #01AF40;
$color-menu-left: #28373E;
$color-menu-right: #FFFFFF;
$color-menu-text:#000000;
$color-menu-overlay:rgba(0,0,0, 0.5);
$color-text: #333333;
$color-text-light: #FFFFFF;
$color-tile-hover:#ffba00;

$color-button-0: #999999;
$color-button-0-hover: #a3a1a1;
$color-button-0-active: #848484;
$color-button-1: #FFb900;
$color-button-1-hover: #ffc52d;
$color-button-1-active: #d89c00;
$color-button-2: #FE0000;
$color-button-2-hover: #ff3333;
$color-button-2-active: #cc0000;
$color-button-3: #01AF40;
$color-button-3-hover: #00d14b;
$color-button-3-active: #00a83c;
$color-button-4: #0079c8;
$color-button-4-hover: #1A93E2;
$color-button-4-active: #0060AF;

$color-status-1: #FFb900;
$color-status-2: #FE0000;
$color-status-3: #01AF40;
$color-status-4: #FFFFFF;

$color-menu-highlight-text: #FFFFFF;
$color-menu-highlight-brand: #0079c8;
$color-inner-menu-highlight: #C4C4C4;
$color-menu-collapse-toggle-border: #EBEBEB;

$color-background: #F2F2F2;
$color-panel: #FFFFFF;
$color-panel-error: #FE0000;
$color-panel-success: #01AF40;
$color-panel-warning: #FFb900;

$color-highlight: #999999;

$color-input-placeholder: #999999;
$color-input-border: #d2d2d2;
$color-input-border-highlight: #ED1C24;
$color-input-focus-outline: #66afe9;

$color-infobox-default: #0079c8;
$color-infobox-opened: #FFB900;
$color-infobox-top-panel-border: #B7B7B7;

$color-table-row-alt: #ebebeb;
$color-table-column-0:#FFFFFF;
$color-table-column-1:#E0E0E0;
$color-table-head-alt: #ffba00;

$color-mandatory-field-text: #FF0000;
$color-deal-status-progress: #990000;

$color-point-badge: #18cd61;
$color-point-badge-descriptor: #00456c;

$color-field-disabled: #eeeeee;
$color-dropdown-item-background: #eeeeee;


$color-loader-overlay:rgba(255,255,255, 0.5);