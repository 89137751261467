$ns: login;

.#{$ns} {


  .main-logo {
    height:43px;
    position: absolute;
    top: 15px;
    left:15px;
    z-index:5;

    @include respond-below(sm)
    {
      height: 35px;
    }
  }



  .#{$ns}-background{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    background-position: center center;
    background-size: cover;

    @include transition(opacity 1s);
  }


  .#{$ns}-panel{
    width:450px;
    position: absolute;
    top:50%;
    left:50%;
    padding:40px 69px;
    background-color: rgba(0,0,0,0.6);
    border-radius: 25px;
    @include transform(translate(-50%, -50%));

    @include respond-below(sm)
    {
      width:300px;
      padding:30px 40px;
    }



    .#{$ns}-panel-title{
      color:white;
      text-align: left;
      margin-bottom: 36px;
      height:35px;
      position: relative;



      .#{$ns}-panel-logo{
        position: absolute;
        height: 35px;
        width: 100%;

        @include transition(opacity 1s);

        img {
          height: 35px;
        }

        .text{
          top: -6px;
          display: inline-block;
          position: relative;
          font-size:32px;

          @include respond-below(sm)
          {
            font-size:24px;
            top: -8px;
          }
        }

        .fade-intro{
          @include transition(opacity 1s);

        }
      }
    }


    .#{$ns}-form{
      margin-bottom: 20px;
      .#{$ns}-panel-form-field{
        display:block;
        width:100%;
        margin-bottom: 20px;
        height:34px;
        border-radius: 3px;
        border:none;
        font-size:15px;
        padding-left:15px;
        line-height:34px;
        padding-right:15px;

      }
    }

    .afs-button {
      width:170px;
      margin: 0 auto;
      display: block;
      height:34px;

      i{
        position:relative;
        top:1px;
      }

      @include respond-below(sm)
      {
        width:100%;
      }
    }

    .#{$ns}-panel-forgot-pass{
        font-size:12px;
        text-align: center;
      a{
        color:white;
      }
    }
  }
}

