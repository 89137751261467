@import "../../sass/variables";
@import "../../sass/helpers";

$ns: fs-dropdown;

//hide original drop downs, to prevent flicker of unstyled elements
.dropdown{
  display:none;
}

.#{$ns}{
  font-family: 'Lato';
  font-style: normal;
  font-size: 14px;
  color: $color-text;

  .dropdown {
    display:inherit;
  }

  &.placeholder-selected{
    .#{$ns}-selected {
      color: $color-input-placeholder;
    }
  }

  @include respond-below(xs){
    margin-top:4px;
  }

  .#{$ns}-selected{
    height: 41px;
    border: solid 1px $color-input-border;
    border-radius: 2px;
    padding: 10px 15px 8px 15px;
    color: $color-text;

    &::after{
      position: absolute;
      right:10px;
      top: 50%;
      transform: translateY(-50%);

      font-family: $font-family-icon;
      content: $dropdown-icon;
      color: $color-input-border;
    }

    @include respond-below(sm){
      padding: 10px;
    }
  }

  .#{$ns}-options{
    border: solid 1px $color-input-border;

    .#{$ns}-item{
      padding: 10px 15px;
      background-color: $color-dropdown-item-background;
    }

    .#{$ns}-item_selected{
      background-color: $color-brand;
      color: $color-text-light;
    }

    .#{$ns}-item:hover{
      background-color: $color-brand;
      color: $color-text-light;
    }

    .#{$ns}-item_placeholder &.#{$ns}-item_selected{
        color: $color-input-placeholder;
    }
  }
}
//Disabled Styles
.#{$ns}-disabled{
  opacity: 1;
  .#{$ns}-selected{
    background-color: $color-field-disabled;
    color: $color-text;
    border: transparent !important;

    &::after{
      display: none;
    }
  }
}