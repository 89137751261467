$ns: collapsible-panel;

.#{$ns} {

  &.variant{
    border-top: 2px solid $color-input-border;
    .#{$ns}-header {
    height:auto;
      a {
       > i {
          position: absolute;
          display: inline-block;
          right:10px;
          top:50%;
          @include transform(translateY(-50%));
          background-color: transparent;
          color:$color-brand;
          font-size:20px;

        }
        span {
          margin-left: 0;
          font-size: 20px;
          cursor: pointer;
          padding: 30px 30px 30px 0px;
          width: 100%;
          color: $color-brand;
          background-color: $color-panel;
          display: block;
          font-weight: normal;
        }
      }
    }

    .#{$ns}-body{
      .content {
        padding-bottom:20px;
      }
    }
  }

  &.variant-1{
    .#{$ns}-header {
      min-height:initial;
      a {
        > i {
          position: absolute;
          display: inline-block;
          height: 36px;
          right:10px;
          top:50%;
          @include transform(translateY(-50%));
          background-color: transparent;
          color:$color-brand;
          font-size:26px;

        }
        span {
          margin-left: 0;
          font-size: 26px;
          cursor: pointer;
          width: 100%;
          color: $color-brand;
          background-color: $color-panel;
          display: block;
          font-weight: normal;
        }
      }
    }
  }

    .#{$ns}-header{
      position: relative;
      min-height:36px;

      &.add-mode{
        a span{
          padding-right:90px;
        }
      }

      .add-button{
        position:absolute;
        top:50%;
        @include transform(translateY(-50%));
        right:20px;
        @include respond-below(sm){
          right:35px;
        }

        &.centred{
          margin-right:0px;
          right:50%;

          @include respond-below(sm){
            margin-right:5px;
            right:35px;
          }
        }
      }

      @include respond-below(sm){
        height:25px;
      }
      a {
        cursor: pointer;
        &:hover{
          text-decoration: none;
        }
        &.collapsed{
          > i::before{
            content: $down-chevron-icon;
          }
        }

        > i {
          background-color: $color-brand;
          height: 20px;
          width: 20px;
          text-align: center;
          border-radius: 3px;
          color: $color-text-light;
          position: absolute;
          top: 4px;
          &::before {
            content: $up-chevron-icon;
          }

          @include respond-below(sm){
            top:-1px;
          }
        }



        span {
          margin-left: 35px;
          font-size: 30px;
          font-weight: 300;
          display: inline-block;

          @include respond-below(sm){
            font-size:18px;
            font-weight: 500;
          }
        }
      }
    }
}