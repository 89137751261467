@import "../../sass/variables";
@import "../../sass/helpers";

$ns: side-panel-section;

.#{$ns}{

  font-family: 'Lato';
  font-style: normal;
  font-size: 20px;
  color: $color-brand;

  padding-left: 20px;
  padding-right: 20px;

  @include respond-below(md){
    width:270px;
    position: fixed;
    left:0;
   // top:0;
    @include transform(translateX(-100%));
    z-index: 10;
    border-right:2px solid $color-menu-collapse-toggle-border;
    height: calc(100vh - #{$header-height});
    top:$header-height;

    .#{$ns}-collapse-toggle{
      display:block !important;
      width:35px;
      height:42px;
      background-color: $color-panel;
      border-top: 2px solid $color-menu-collapse-toggle-border;
      border-right: 2px solid $color-menu-collapse-toggle-border;
      border-bottom: 2px solid $color-menu-collapse-toggle-border;

      position: absolute;
      top: 160px;
      right: -35px;
      margin-top: -2px;

      .#{$ns}-collapse-toggle-icon{

        &::before{
          font-family:$font-family-icon;
          font-weight: 100;
          content: $right-chevron-icon;
          width: 100%;
          min-width: 17px;
          display: block;
          line-height: 16px;
        }

        display: block;
        text-decoration: none;
        color: $color-brand;

        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translateX(-50%) translateY(-50%));
      }
    }

    @include transition(transform .25s linear);

    &.open{
      @include transform(translateX(0%));

      .#{$ns}-collapse-toggle .#{$ns}-collapse-toggle-icon{
        &::before{
          content: $left-chevron-icon;
        }
      }
    }
  }

  &.sticky{
    @include respond-above(md){
      @include transform(translate3d(0,0,0));
      z-index: 5;
      position: fixed;
      top: 195px;
      width: 25%;
      max-width: 212px;

      @include respond-above(lg){
        max-width: 262px;
      }
    }
  }

  &.#{$ns}-white{
    background-color: $color-panel;
  }

  .#{$ns}-title{
    padding-top: 30px;
    padding-bottom: 25px
  }

  .#{$ns}-menu {
    @include respond-below(md){

      overflow: auto;
      height: calc(100vh - #{$header-height + 90px});
      width: calc(100% + 40px);
    }
    margin:0 -20px;
    padding-left:20px;
    padding-right:20px;

    .menu-item {
      border-bottom: solid 1px $color-background;

      >a{
        display:block;
        text-decoration: none;
        color: $color-brand;
        padding-top: 15px;
        padding-bottom: 15px;
        margin: 0 -20px;
        padding-left: 20px;
        padding-right: 20px;
        @include transition(color $hover-transition-duration, background-color $hover-transition-duration);

        &:hover{
          color: $color-menu-highlight-text;
          background-color: $color-menu-highlight-brand;

          text-decoration: none;
          cursor: default;
        }

        &.warning{
          color:$color-status-1;
        }
        &.error{
          color:$color-status-2
        }
      }

      &.no-border {
        border-bottom: none;
      }

      &.active{
        >a{
          @include transition(color $hover-transition-duration, background-color $hover-transition-duration);

          background-color: $color-menu-highlight-brand;
          color: $color-menu-highlight-text;
          margin: 0 -20px;
          padding-left: 20px;
          padding-right: 20px;

          &.warning{
            color:$color-status-1;
          }
          &.error{
            color:$color-status-2
          }
        }

        .#{$ns}-menu-inner {
          display:block;
        }
      }

      .#{$ns}-menu-inner{

        display:none;

        >li{
          border-bottom: solid 1px $color-background;
          margin: 0 -20px;

          &.no-border {
            border-bottom: none;
          }

          &.active{
            >a{
              background-color: $color-inner-menu-highlight;
              color: $color-menu-highlight-text;
            }
          }

          >a{
            padding-left: 20px;
            padding-right: 20px;
            display:table;
            width:100%;
            padding-top: 10px;
            padding-bottom: 10px;
            text-decoration: none;
            color: $color-text;
            cursor: pointer;
            font-size:16px;

            @include respond-in(md){
              padding-left: 15px;
              padding-right: 15px;
            }

            .status{
              display:table-cell;
              vertical-align: top;
              width:30px;
            }
            .text{
              @include transition(font-size $hover-transition-duration);

              display:table-cell;
              vertical-align: top;
            }

            &:hover{
              .text {
                font-size: 22px;

                @include respond-in(md){
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      .#{$ns}-menu-inner>li{
        &.menu-inner-highlight{
          background-color: $color-inner-menu-highlight;
          color: $color-menu-highlight-text;
          margin: 0 -20px;
          padding-left: 20px;
          padding-right: 20px;

        }
      }
    }
  }

  .#{$ns}-collapse-toggle{
    display: none;
  }
}

.colored-bullet{
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 1px $color-background;
  margin-right: 18px;

  @include respond-in(md){
    width: 14px;
    height: 14px;
    margin-right: 12px;
  }

  &.status-1{
    background-color: $color-status-1;
  }
  &.status-2{
    background-color: $color-status-2;
  }
  &.status-3{
    background-color: $color-status-3;
  }
  &.status-4{
    background-color: $color-status-4;
  }
}