@mixin nav-menu-left-font {
  font-size:12px;
  font-weight:300;
}

@mixin nav-menu-level-title-font {
  font-size:16px;
  font-weight:500;
}


@mixin nav-menu-level-item-name {
  font-size:14px;
  font-weight:700;
}

@mixin nav-menu-level-item-desc {
  font-size:12px;
  font-weight:300;
}


@mixin nav-menu-top{
  font-size:16px;
  font-weight:500;
}