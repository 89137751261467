$ns: dashboard;

.#{$ns} {
  //background-color: #00d14b; //hello im a test :3

  .greeting-section{
    background-color: $color-panel;
  }
  .table-panel {
    padding-left:60px;
   // padding-bottom:30px;

    .table-responsive{
      border:none;
    }
    .table{
      min-width: 900px;
      &.variant{
        min-width: auto;
        max-width: 600px;
      }
    }
    .collapsible-panel {
      margin-top: 20px;
      @include respond-at-below(md){
        margin-top: 15px;
      }
      .collapsible-panel-header {
        margin-bottom: 10px;
      }
      .collapsible-panel-body{
        padding-left:35px;
        padding-right:35px;
        @include respond-at-below(md){
          padding-left:0;
          padding-right: 0;
        }
      }
    }

    @include respond-at-below(md){
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .table-how-to{
    text-align: center;
    padding-top:15px;
    padding-bottom:15px;
    display:none;

    @include respond-below(sm)
    {
      display:block;
    }
  }
}

