$ns: shortcuts;

.#{$ns} {
  background-color: $color-brand;

  ul{
    max-width:800px;
    display:block;
    margin:0 auto;
    font-size:0;
    padding-top:40px;
    padding-bottom:40px;

  }
    .icon-tile{
      display:inline-block;
      width:20%;


      .icon{
        height:100px;
        width:100px;
      }

      @include respond-below(md){
        width:25%;

      }
      @include respond-below(sm){
        width:33%;
        margin:0;

        .icon{
          height:68px;
          width:68px
        }
      }
    }
}