@import "../../sass/variables";
@import "../../sass/helpers";

$ns: infobox;

.#{$ns}-icon {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  z-index: 5;
  width: 18px;
  height: 18px;

  @include respond-below(sm)
  {
    margin-left: -4px;
  }

  .#{$ns}-icon-symbol {
    position: absolute;
    display: block;
    width: 100%;
    min-width: 14px;
    cursor: pointer;
    padding-left:4px;
    font-family: Helvetica;
    font-weight:bold;
    font-size: 12px;
    color: $color-background;
    z-index: 2;

    background-color: $color-infobox-default;
    line-height: 16px;
    border: solid 1px $color-background;
    border-radius: 50%;

    @at-root .open#{&}{
      background-color: $color-infobox-opened;
    }

    &:hover {
      text-decoration: none;
      color: $color-background;
    }
  }

  .#{$ns}-text {
    display: none;
    padding: 10px;
    width: 225px;
    position: absolute;
    right: 10px;
    top:50%;
    @include transform(translateY(-50%));

    background-color: $color-brand;
    color: $color-background;
    font-size:13px;

    z-index: 1;

    @at-root .open#{&}{
      display: block;
    }
  }
}

