@import "resets";
@import "variables";
@import "helpers";
@import "fonts";

// Components

@import "../components/header/styles";
@import "../components/panel/styles";
@import "../components/side_panel/styles";
@import "../components/top_panel/styles";
@import "../components/infobox/styles";
@import "../components/checkbox/styles";
@import "../components/dropdown/styles";
@import "../components/buttons/styles";
@import "../components/greeting/styles";
@import "../components/icon_tile/styles";
@import "../components/table/styles";
@import "../components/shortcuts/styles";
@import "../components/collapsible_panel/styles";
@import "../components/footer/styles";
@import "../components/point_badge/styles";
@import "../components/notes_modal/styles";
@import "../components/form_controls/styles";
@import "../components/marketing_box/styles";
@import "../components/bulletin_board/styles";
@import "../components/loader/styles";



// Pages
@import "pages/login";
@import "pages/dashboard";
@import "pages/financed_asset";
@import "pages/finance_details";

body{
  font-family: 'Lato';
  background-color: $color-background;
}

.page-wrapper{
  padding-top:$header-height;
}

.container{
  @include respond-below(xs){
    padding-left:10px;
    padding-right:10px;
  }
}

.no-side-margin{
  margin-left:0px;
  margin-right:0px;
}

.top-spacing{
  margin-top:12px;
}

.mobile-top-spacing{
  @include respond-below(sm){
    margin-top:12px;
  }
}

.bottom-spacing{
  margin-bottom:12px;
}

.tablet-bottom-spacing{
  @include respond-below(md){
    margin-bottom:12px;
  }
}

.mobile-bottom-spacing{
  @include respond-below(sm){
    margin-bottom:12px;
  }
}

.relative-container{
  position: relative;
}

.p-b-20{
  padding-bottom: 20px;
}

.p-b-30{
  padding-bottom: 30px;
}


.no-p-l {
  padding-left:0;
}

.no-p-l-sm{
  @include respond-above(sm)
  {
    padding-left: 0;
  }
}

.no-p-l-md{
  @include respond-above(md)
  {
    padding-left: 0;
  }
}

.p-r-5 {
  padding-right:5px;
}

.p-r-5-sm {
  @include respond-above(sm)
  {
    padding-right:5px;
  }
}

.no-p{
   padding:0;
 }
.no-m{
  margin:0;
}

.label{
  &.label-default{
    background-color: $color-button-0;
    font-size:14px;
    font-weight: normal;
  }
}
