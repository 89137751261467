@import "../../sass/variables";
@import "../../sass/helpers";

$ns: financed-asset;

.#{$ns} {

  .grid-cells{
    .col-title{
      padding-bottom: 15px;
      padding-left:10px;
    }

    .grid-row{
      .title{
        @include respond-below(sm) {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 15px;
          display: block;
          margin-left: -15px;
          margin-top: 10px;
        }
      }
    }
    .form-group{
      .control-label {
        padding-left:15px;
      }
      @include respond-above(sm)
      {
        margin-left:0;
        margin-right:0;
      }
    }
  }

  .#{$ns}-spec-table {

    display: table;

    .#{$ns}-spec-table-header {
      background-color: $color-brand;
      border-bottom-left-radius: 10px;
      color: $color-text-light;
      min-height:50px;
      label{
        position: absolute;
        width:100%;
        top:50%;
        @include transform(translateY(-50%));
      }



      @include respond-below(md)
      {
        border-bottom-left-radius: 0;
      }
    }

    .#{$ns}-spec-table-body
    {
        padding-left: 0;
        padding-right: 0;
    }

    .#{$ns}-spec-body{
      margin-top:10px;

      border-top: 2px solid $color-brand;

      @include respond-below(md) {

        border-top:none;
      }
    }

    .#{$ns}-spec-table-row{
      display: table;
      table-layout: fixed;
      height: 100%;
      width:100%;
    }

    .#{$ns}-spec-table-column {
      padding: 15px 15px;
      span{
        display: table-cell;
        height: 50px;
        vertical-align: top;

        @include respond-below(lg)
        {
          height:64px;
        }
        @include respond-below(md)
        {
          height:50px;
        }
        @include respond-below(sm)
        {
          height:64px;
        }
      }

      @include respond-below(sm){
        height: 80px;
      }

      &.column-color-0{
        background-color: $color-table-column-0;
      }
      &.column-color-1{
        background-color: $color-table-column-1;
      }
    }
  }
}