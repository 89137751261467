$ns: loader;

.#{$ns} {

  position: fixed;
  z-index: 100000000;
  height:100vh;
  width:100%;
  background-color: $color-loader-overlay;

  .#{$ns}-logo-wrapper{
    height:120px;
    width:120px;
    position: absolute;
    top:50%;
    left:50%;
    @include transform(translate(-50%, -50%));
    .#{$ns}-logo{
      @include animation(spin, 2s, infinite, linear);

      @include keyframes(spin) {
        from {
          @include transform(rotate(0deg))
        }
        to {
          @include transform(rotate(360deg))
        }
      }
    }
  }
}